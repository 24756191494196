import { PureComponent } from 'react';
import AuthenticationContext, { AuthContextType } from '../../../../../../../Authentication/types/AuthContextType';
import { HWPackageContainerProps, HWPackageContainerState, HardwarePackage, RowHWPackageTable } from '../types/HWPackageTypes';
import NoSmartComponentsHttpClient from '../../../../../../../HttpClient/NoSmartComponentsHttpClient';
import _ from 'lodash';
import Message from '../../../../../../Message/Message';
import Loader from '../../../../../../Common/Loader';
import HWPackageView from '../views/HWPackageView';
import { TableItemHWHardwareTable } from '../../HardwareManagement/types/HWHardwareTypes';
import { t } from 'i18next';

class HWPackageContainer extends PureComponent<HWPackageContainerProps, HWPackageContainerState> {
  static contextType = AuthenticationContext;

  constructor(props: HWPackageContainerProps) {
    super(props);
    this.state = {
      filter: null,
      dataLoaded: false,
      page: 1,
      sizePerPage: 5,
      message: null,
      allHardwarePackage: [],
      allHardwarePackageFiltered: [],
      showConfirmDeletePackageModal: false,
      showAddPackageModal: false,
      packageToDelete: null,
      showAddHWModal: false,
      rowSelectedToEdit: null,
    };

    this.onSizePerPageChange.bind(this);
    this.onPageChange.bind(this);
    this.handleChangeFilter.bind(this);
    this.patchCheck.bind(this);
    this.handleShowConfirmDeletePackageModal.bind(this);
    this.handleShowAddPackageModal.bind(this);
    this.handleEditPackage.bind(this);
    this.deletePackage.bind(this);
  }

  async componentDidMount() {
    this.getPageAndSizeOfTable();
    await this.getHardwarePackages();
  }

  patchCheck = async (value: boolean, objCheck: TableItemHWHardwareTable, checkName: string) => {
    try {
      const patchObjValue = [
        {
          op: 'replace',
          path: `/${checkName}`,
          value: String(value),
        },
      ];
      const httpNoSmartCompo = new NoSmartComponentsHttpClient(this.context);
      let data = await httpNoSmartCompo.patchNoSmartComponentPackage(objCheck.row.id, patchObjValue);
      if (data.content) {
        this.setMessage('success', t('firmwareManagementPage.NoSmartComponent.available.ok'));
        this.refreshTable();
      } else {
        this.setMessage('error', t('firmwareManagementPage.NoSmartComponent.available.ko'));
      }
    } catch (e) {
      this.setMessage('error', t('firmwareManagementPage.NoSmartComponent.available.ko'));
    }
  };

  setMessage(type: string, text: string) {
    this.setState({ message: { type: type, text: text } });
  }

  refreshTable = () => {
    this.setState(
      { dataLoaded: false, showConfirmDeletePackageModal: false, showAddPackageModal: false, packageToDelete: null },
      () => {
        this.getHardwarePackages();
      }
    );
  };

  handleShowConfirmDeletePackageModal = (status: boolean, rowPackage?: RowHWPackageTable) => {
    const packageToDelete = status && rowPackage ? rowPackage : null;
    this.setState({ showConfirmDeletePackageModal: status, packageToDelete });
  };

  getHardwarePackages = async () => {
    const httpNoSmartCompo = new NoSmartComponentsHttpClient(this.context);
    const allHardwarePackage = await httpNoSmartCompo.GetNoSmartComponentPackages();
    if (allHardwarePackage?.content) {
      const allHardwarePackageWithTableFields = this.prepareDataForTable(allHardwarePackage.content);
      this.setState({
        allHardwarePackage: allHardwarePackageWithTableFields,
        allHardwarePackageFiltered: _.cloneDeep(allHardwarePackageWithTableFields),
        dataLoaded: true,
      });
    }
  };

  deletePackage = async () => {
    if (!this.state.packageToDelete) return null;
    try {
      const httpNoSmartCompo = new NoSmartComponentsHttpClient(this.context);

      // delete dependencies
      if (this.state.packageToDelete.noSmartComponents) {
        const { noSmartComponents, id } = this.state.packageToDelete;
        for (const noSmartCompo of noSmartComponents) {
          await httpNoSmartCompo.deleteNoSmartComponentDependenciesPackage(noSmartCompo.id, id);
        }
      }

      // delete packageid
      const response = await httpNoSmartCompo.deleteNoSmartComponentPackage(this.state.packageToDelete.id);
      if (response && response.data && response.data.content) {
        // ok
        this.setMessage('success', t('firmwareManagementPage.NoSmartComponent.delete.ok'));
        this.refreshTable();
      } else {
        //ko
        this.setMessage('error', t('firmwareManagementPage.NoSmartComponent.delete.ko'));
      }
    } catch (e) {
      console.log(e);
      this.setMessage('error', t('firmwareManagementPage.NoSmartComponent.delete.ko'));
    }
  };

  prepareDataForTable = (dataContent) => {
    const dataWithNewFields = dataContent.map((element) => {
      return {
        ...element,
        isNoSmartComponentType: Boolean(element.noSmartComponentType),
        idType: element.smartComponentType?.id || element.noSmartComponentType?.id,
      };
    });
    return dataWithNewFields;
  };

  handleChangeFilter = (value: { label: string; value: string } | null) => {
    const { allHardwarePackage } = this.state;
    if (value) {
      const allHardwarePackageFiltered = allHardwarePackage.filter(
        (HWpackage: HardwarePackage) => value.value === HWpackage.systemId
      );
      this.setState({ filter: value, allHardwarePackageFiltered });
    } else {
      this.setState({ filter: value, allHardwarePackageFiltered: allHardwarePackage });
    }
  };

  getPageAndSizeOfTable = () => {
    const context = this.context as AuthContextType;
    const pageValue = context.manageLocalStorage('get', 'HWPackageTablePage');
    const sizeValue = context.manageLocalStorage('get', 'HWPackageTableSizePerPage');
    const page = pageValue ? Number(pageValue) : 1;
    const sizePerPage = sizeValue ? Number(sizeValue) : 5;
    this.setState({ page, sizePerPage });
  };

  onSizePerPageChange = (sizePerPage: number) => {
    localStorage.setItem('HWPackageTableSizePerPage', sizePerPage.toString());
    this.setState({ sizePerPage: sizePerPage });
  };

  onPageChange = (newPage: number) => {
    localStorage.setItem('HWPackageTable', newPage.toString());
    this.setState({ page: newPage });
  };

  handleMessageClose = () => {
    this.setState({ message: null });
  };

  handleShowAddPackageModal = (status: boolean, refresh = false, message?:  { type: string; text: string } ) => {
    this.setState({ showAddPackageModal: status, rowSelectedToEdit: null });
    if (refresh) {
      this.refreshTable();
    }
    if (message) {
      this.setMessage(message.type, t(message.text));
    }
  };

  handleEditPackage = (rowPackage: RowHWPackageTable) => {
    this.setState({ showAddPackageModal: true, rowSelectedToEdit: rowPackage });
  };

  render() {
    return (
      <>
        {this.state.message && (
          <Message type={this.state.message.type} content={this.state.message.text} onClose={this.handleMessageClose} />
        )}
        {this.state.dataLoaded ? (
          <HWPackageView
            {...this.state}
            onSizePerPageChange={this.onSizePerPageChange}
            onPageChange={this.onPageChange}
            handleChangeFilter={this.handleChangeFilter}
            handleShowConfirmDeletePackageModal={this.handleShowConfirmDeletePackageModal}
            patchCheck={this.patchCheck}
            handleShowAddPackageModal={this.handleShowAddPackageModal}
            deletePackage={this.deletePackage}
            handleEditPackage={this.handleEditPackage}
            allSmartComponentTypes={this.props.allSmartComponentTypes}
            systemIds={this.props.systemIds}
            allHardwarePackage={this.state.allHardwarePackage}
            allNoSmartComponents={this.props.allNoSmartComponents}
            allNoSmartComponentsTypes={this.props.allNoSmartComponentsTypes}
            refreshAllNoSmartComponents={this.props.refreshAllNoSmartComponents}
            rowSelectedToEdit={this.state.rowSelectedToEdit}
          />
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

export default HWPackageContainer;
