import React from 'react';
import Style from '../styles/ModalInstallationLocationZoomView.module.scss';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { t } from 'i18next';
import { ModalZoomMapViewProps } from '../types/DNDTypes';

const ModalZoomMapView = (props: ModalZoomMapViewProps) => {
  return (
    <Modal size='lg' show={props.show} onHide={() => props.handlerZoomShowModal(false)} className='zoomContainer'>
      <Modal.Body className={Style.ModalInstallationLocationZoomView}>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button className='primary' onClick={() => props.handlerZoomShowModal(false)}>
          {t('actions.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalZoomMapView;
