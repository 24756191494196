import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { useEffect } from 'react';

export function Draggable({ id, product, children, active, editMode, maxWidthMap, maxHeightMap, maxHeightContent, iteration }) {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id,
    disabled: !editMode,
    data: {
      fromDroppableId: active ? 'floorPlan-droppable' : 'unused-droppable',
      fromStatus: active ? 'active' : 'inactive',
    },
  });

  // Lock horizontal scroll
  useEffect(() => {
    const disableScroll = () => {
      const container = document.querySelector('.installations-swipeable') as HTMLElement | null;
      if (container) {
        container.style.overflow = 'hidden';
      }
    };

    const enableScroll = () => {
      const container = document.querySelector('.installations-swipeable') as HTMLElement | null;
      if (container) {
        container.style.removeProperty('overflow');
      }
    };

    if (isDragging) {
      disableScroll();
    } else {
      enableScroll();
    }

    return () => {
      enableScroll();
    };
  }, [isDragging]);

  const checkLimits = (transform) => {
    if (active) {
      // Active element
      if (transform?.x !== undefined && transform?.y !== undefined) {
        const currentX = product.floorPlanPixel?.x || 0;
        const currentY = product.floorPlanPixel?.y || 0;

        const newPositionX = currentX + transform.x;
        const newPositionY = currentY + transform.y;

        // Limit to the edges of the container
        const constrainedX = Math.min(Math.max(newPositionX, 0), maxWidthMap);
        const constrainedY = Math.min(Math.max(newPositionY, 0), maxHeightContent - 28);

        return {
          scaleX: 1,
          scaleY: 1,
          x: constrainedX - currentX,
          y: constrainedY - currentY,
        };
      }
    } else {
      // inactive element, in this case the limits do not apply since we cannot control the coordinate in which it is initially positioned
      return transform;   
    }

    return transform;
  };

  // Apply limits to the transform
  const adjustedTransform = checkLimits(transform);

  const style = {
    display:
      active && product.floorPlanPixel && (product.floorPlanPixel.y === 0 || product.floorPlanPixel.x === 0) ? 'none' : undefined,
    transform: CSS.Translate.toString(adjustedTransform),
    position: active ? 'absolute' : 'static',
    top: active && product.floorPlanPixel?.y,
    left: active && product.floorPlanPixel?.x,
    padding: '0px',
    backgroundColor: 'transparent',
  };

  return (
    <button ref={setNodeRef} className={isDragging ? 'dragging' : ''} style={style as any} {...listeners} {...attributes}>
      {children}
    </button>
  );
}
