import { useEffect } from 'react';
import Style from '../styles/DNDView.module.scss';
import { DNDViewProps } from '../types/DNDTypes';
import { DndContext } from '@dnd-kit/core';
import { Droppable } from './Droppable';
import { Draggable } from './Draggable';

import Button from '@mui/material/Button';
import { Popover, Tooltip, Whisper } from 'rsuite';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import PublishIcon from '@mui/icons-material/Publish';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { t } from 'i18next';
import moment from 'moment';
import Message from '../../../../../components/Message/Message';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

const DNDView = (props: DNDViewProps) => {
  const replaceIconUriColor = (iconUri, targetColor = 'black') => {
    const currentColor = iconUri.includes('_white') ? 'white' : 'black';
    return iconUri.replace(`_${currentColor}`, `_${targetColor}`);
  };

  useEffect(() => {
    // Update dimensions on mount and when window resizes
    props.calculateActiveProductsCoords(props.parentClass);
    window.addEventListener('resize', () => props.calculateActiveProductsCoords(props.parentClass));
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', () => props.calculateActiveProductsCoords(props.parentClass));
    };
  }, []);

  const tooltip = (text: string) => {
    return <Tooltip style={{ textTransform: 'capitalize' }}>{text.toLowerCase()}</Tooltip>;
  };

  const renderProducts = (products, active = true) =>
    products.map((product, key) => (
      <Draggable
        id={product.puuid}
        editMode={props.editMode}
        product={product}
        key={product.puuid}
        active={active}
        maxHeightContent={props.maxHeightContent}
        maxHeightMap={props.maxHeightMap}
        maxWidthMap={props.maxWidthMap}
        iteration={!active && key}
      >
        <div key={product.tag} className={`product ${active && 'active'}`}>
          <Whisper placement='auto' trigger={props.editMode ? 'none' : 'hover'} speaker={renderPopover(product)}>
            <div className={`stateDiv ${props.editMode && 'editMode'} mapProd`} x-puuid={product.puuid}>
              <div>
                <img className={`imgState state-${product.connectionState}`} alt={`Connection state of product ${product.tag}`} />
                <img className='iconUri' src={replaceIconUriColor(product.productType.iconUri, 'white')} />
                <span>{`${product.productType.letter}${product.tag}`}</span>
              </div>
            </div>
          </Whisper>
        </div>
      </Draggable>
    ));

  const renderPopover = (product) => {
    const dataDesignLine =
      product.designLine == null
        ? product.productType.name
        : `${product.designLine} - ${product.productType.letter}${product.tag}`;
    const dataLastData = product.lastProcessedValue;
    const dataLastTimeSeen = product.lastTimeSeen;
    let lastDataLabel = '';
    let lastDataTitleLabel = '';
    if (product.connectionState === 'online') {
      lastDataLabel = moment(dataLastData).format('D MMM YYYY HH:mm');
      lastDataTitleLabel = `${t('installationspages.installationTree.lastdata')}:`;
    } else {
      lastDataLabel = moment(dataLastTimeSeen).format('D MMM YYYY HH:mm');
      lastDataTitleLabel = `${t('productspages.general.lastTimeSeen')}:`;
    }

    return (
      <Popover>
        <div className='popover-content'>
          <div style={{ width: '60px', height: '60px' }}>
            <img src={product.productType.imageUri} style={{ height: '100%' }} />
          </div>
          <div>
            <div>
              <h5>{dataDesignLine}</h5>
            </div>
            <div>
              <h6 className='d-inline'>REF: </h6>
              <span style={{ marginTop: '-3px' }} className='t3 d-inline'>
                {product.productType.sku}
              </span>
            </div>
            <div>
              <h6>{product.productType.name}</h6>
            </div>
            <div>
              <h6 className='d-inline'>PUUID: </h6>
              <span className='t3 d-inline oneLine'>{product.puuid}</span>
            </div>
            <div>
              <h6 className='d-inline'>{t('productspages.summary.warranty')}: </h6>
              <span>{product.warranty === '' ? 'No' : product.warranty}</span>
            </div>
            <div>
              <h6 className='d-inline'>{lastDataTitleLabel} </h6>
              <span className='t3 d-inline oneLine'>{lastDataLabel}</span>
            </div>
          </div>
        </div>
      </Popover>
    );
  };

  return (
    <>
      {props.message && <Message {...props.message} onClose={() => props.renderMessage('close')} />}
      <DndContext onDragEnd={props.newProductPosition}>
        <div className={Style.DNDView}>
          <div className={`DND-content ${(props.showZoomModal || props.loader) ? 'blur': ''}`}>
            <div className={`box-floorPlan ${props.editMode && 'editMode'}`}>
              <Droppable id='floorPlan-droppable'>
                <div className='box-imagen'>
                  {props.products && props.products.coordsInPixel && renderProducts(props.products.active)}
                  <img id='floorPlanImg' src={props.floorPlan} alt='Floor plan' onLoad={() => props.calculateActiveProductsCoords(props.parentClass)} />
                </div>
              </Droppable>
            </div>

            <div className='controls'>
              {!props.editMode && (
                <Whisper
                  placement='bottom'
                  trigger='hover'
                  delayOpen={500}
                  speaker={tooltip(t('actions.editname', { name: t('installationspages.layout') }))}
                >
                  <Button className='managmentBtnClass' onClick={() => props.changeMode(true)}>
                    <EditIcon />
                  </Button>
                </Whisper>
              )}
            </div>
            <div className='box-unused'>
              <Droppable id='unusued-droppable'>{props.products && renderProducts(props.products.inactive, false)}</Droppable>
            </div>
            {props.editMode && !props.loader && (
              <div className='editMode-controls'>
                <div className='btns'>
                  <Whisper
                    placement='bottom'
                    trigger='hover'
                    delayOpen={500}
                    speaker={tooltip(t('installationspages.installationsummaryedit.uploadimage'))}
                  >
                    <Button className='managmentBtnClass' component='label'>
                      <input
                        type='file'
                        accept={props.fileTypesAllowed.map((type) => `.${type}`).join(',')}
                        hidden
                        onChange={props.handleUploadImage}
                      />
                      <PublishIcon />
                    </Button>
                  </Whisper>
                  <Whisper
                    placement='bottom'
                    trigger='hover'
                    delayOpen={500}
                    speaker={tooltip(t('actions.deletename', { name: t('general.floorplan') }))}
                  >
                    <Button
                      className='managmentBtnClass'
                      disabled={!props.floorPlan || props.floorPlan === ''}
                      onClick={() => props.handleConfirmDeleteModal()}
                    >
                      <DeleteOutlineIcon />
                    </Button>
                  </Whisper>
                </div>
                <div className='btns'>
                  <Whisper placement='bottom' trigger='hover' delayOpen={500} speaker={tooltip(t('actions.rotate-left'))}>
                    <Button
                      className='managmentBtnClass'
                      onClick={() => props.handleRotateImage(270)}
                      disabled={!props.floorPlan || props.floorPlan === ''}
                    >
                      <RotateLeftIcon />
                    </Button>
                  </Whisper>
                  <Whisper placement='bottom' trigger='hover' delayOpen={500} speaker={tooltip(t('actions.rotate-right'))}>
                    <Button
                      className='managmentBtnClass'
                      style={{ minWidth: 0 }}
                      onClick={() => props.handleRotateImage(90)}
                      disabled={!props.floorPlan || props.floorPlan === ''}
                    >
                      <RotateRightIcon />
                    </Button>
                  </Whisper>
                </div>
                <div className='btns'>
                  <Whisper
                    placement='bottom'
                    trigger='hover'
                    delayOpen={500}
                    speaker={tooltip(t('actions.savename', { name: t('installationspages.layout') }))}
                  >
                    <Button className='managmentBtnClass' onClick={() => props.handleSave()}>
                      <CheckIcon />
                    </Button>
                  </Whisper>
                  <Whisper
                    placement='bottom'
                    trigger='hover'
                    delayOpen={500}
                    speaker={tooltip(t('actions.cancel'))}
                    onClick={() => props.changeMode(false)}
                  >
                    <Button className='managmentBtnClass'>
                      <ClearIcon />
                    </Button>
                  </Whisper>
                </div>
              </div>
            )}
          </div>
        </div>
      </DndContext>

      {/* Confirm Delete Image */}
      {props.showConfirmDeleteModal && (
        <Dialog
          open={props.showConfirmDeleteModal}
          onClose={props.handleConfirmDeleteModal}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{t('actions.delete')}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {t('actions.confirmdelete', { name: t('general.thisImage').toLowerCase() })}
            </DialogContentText>
            <DialogActions>
              <Button className='primary pull-right' onClick={props.handleConfirmDeleteModal}>
                {t('actions.no')}
              </Button>
              <Button className='primary pull-right' onClick={props.handleDeleteImage}>
                {t('actions.yes')}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default DNDView;
