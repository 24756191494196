import { PureComponent } from 'react';
import AuthenticationContext, { AuthContextType } from '../../../../../../../Authentication/types/AuthContextType';
import NoSmartComponentsHttpClient from '../../../../../../../HttpClient/NoSmartComponentsHttpClient';
import Loader from '../../../../../../Common/Loader';
import _ from 'lodash';
import Message from '../../../../../../Message/Message';
import { t } from 'i18next';
import HWHardwareView from '../views/HWHardwareView';
import {
  HWHardwareContainerProps,
  HWHardwareContainerState,
  TableItemHWHardwareTable,
  RowHWHardwareTable,
} from '../types/HWHardwareTypes';

class HWHardwareContainer extends PureComponent<HWHardwareContainerProps, HWHardwareContainerState> {
  static contextType = AuthenticationContext;

  constructor(props: HWHardwareContainerProps) {
    super(props);
    this.state = {
      filter: null,
      secondaryFilter: null,
      allNoSmartComponents: [],
      noSmartsFiltered: [],
      dataLoaded: false,
      page: 1,
      sizePerPage: 5,
      showAddHWModal: false,
      showConfirmDeleteHWModal: false,
      message: null,
      idNoSmartToDelete: null,
    };

    this.onSizePerPageChange.bind(this);
    this.onPageChange.bind(this);
    this.handleChangeFilter.bind(this);
    this.handleChangeSecondaryFilter.bind(this);
    this.handleShowAddHWModal.bind(this);
    this.patchAvailableCheck.bind(this);
    this.deleteHardware.bind(this);
  }

  async componentDidMount() {
    this.getPageAndSizeOfTable();
    await this.getNoSmartComponents();
  }

  prepareDataForTable = (dataContent) => {
    const dataWithNewFields = dataContent.map((element) => {
      return {
        ...element,
        isNoSmartComponentType: Boolean(element.noSmartComponentType),
        idType: element.smartComponentType?.id ?? element.noSmartComponentType?.id,
      };
    });
    return dataWithNewFields;
  };

  getNoSmartComponents = async () => {
    const httpNoSmartCompo = new NoSmartComponentsHttpClient(this.context);
    const allNoSmartComponents = await httpNoSmartCompo.GetNoSmartComponents();
    if (allNoSmartComponents?.content) {
      const allNoSmartComponentsWithTableFields = this.prepareDataForTable(allNoSmartComponents.content);
      this.setState({
        allNoSmartComponents: allNoSmartComponentsWithTableFields,
        noSmartsFiltered: _.cloneDeep(allNoSmartComponentsWithTableFields),
        dataLoaded: true,
      });
    }
  };

  getPageAndSizeOfTable = () => {
    const context = this.context as AuthContextType;
    const pageValue = context.manageLocalStorage('get', 'HWHardwareTablePage');
    const sizeValue = context.manageLocalStorage('get', 'HWHardwareTableSizePerPage');
    const page = pageValue ? Number(pageValue) : 1;
    const sizePerPage = sizeValue ? Number(sizeValue) : 5;
    this.setState({ page, sizePerPage });
  };

  handleChangeFilter = (value: { label: string; value: number } | null) => {
    const { allNoSmartComponents } = this.state;
    if (value) {
      const hardwareTypes = {
        1: 'noSmartComponentType',
        2: 'smartComponentType',
      };
      const typeSmartCompo: string = hardwareTypes[value.value];
      const noSmartsFiltered = allNoSmartComponents.filter((component) => component[typeSmartCompo]);
      this.setState({ filter: value, noSmartsFiltered });
    } else {
      this.setState({ filter: value, noSmartsFiltered: allNoSmartComponents });
    }
  };

  handleChangeSecondaryFilter = (value: { label: string; value: string } | null) => {
    const { allNoSmartComponents } = this.state;
    if (value) {
      const noSmartsFiltered = allNoSmartComponents.filter(
        (smartCompo) => value.label === smartCompo.name && value.value === `${smartCompo.name}_${smartCompo.id}`
      );
      this.setState({ secondaryFilter: value, noSmartsFiltered });
    } else {
      this.setState({ secondaryFilter: value, noSmartsFiltered: allNoSmartComponents });
    }
  };

  onSizePerPageChange = (sizePerPage: number) => {
    localStorage.setItem('HardwareTableSizePerPage', sizePerPage.toString());
    this.setState({ sizePerPage: sizePerPage });
  };

  onPageChange = (newPage: number) => {
    localStorage.setItem('HardwareTablePage', newPage.toString());
    this.setState({ page: newPage });
  };

  handleShowAddHWModal = (status: boolean, refresh = false) => {
    this.setState({ showAddHWModal: status });
    if (refresh) {
      this.refreshTable();
    }
  };

  patchAvailableCheck = async (value: boolean, objCheck: TableItemHWHardwareTable) => {
    try {
      const patchObjValue = [
        {
          op: 'replace',
          path: '/available',
          value: String(value),
        },
      ];
      const httpNoSmartCompo = new NoSmartComponentsHttpClient(this.context);
      let data = await httpNoSmartCompo.patchNoSmartComponent(objCheck.row.id, patchObjValue);
      if (data.content) {
        this.setMessage('success', t('firmwareManagementPage.NoSmartComponent.available.ok'));
        this.refreshTable();
      } else {
        this.setMessage('error', t('firmwareManagementPage.NoSmartComponent.available.ko'));
      }
    } catch (e) {
      this.setMessage('error', t('firmwareManagementPage.NoSmartComponent.available.ko'));
    }
  };

  deleteHardware = async () => {
    try {
      const httpNoSmartCompo = new NoSmartComponentsHttpClient(this.context);
      const response = await httpNoSmartCompo.deleteNoSmartComponent(this.state.idNoSmartToDelete);
      if (response && response.status === 200) {
        this.setMessage('success', t('firmwareManagementPage.NoSmartComponent.delete.ok'));
        this.refreshTable();
        this.props.refreshAllNoSmartComponents();
        // this.props.refreshDataTables();
      } else {
        this.setMessage('error', t('firmwareManagementPage.NoSmartComponent.delete.ko'));
      }
    } catch (e) {
      console.log(e);
      this.setMessage('error', t('firmwareManagementPage.NoSmartComponent.delete.ko'));
    }
  };

  handleMessageClose = () => {
    this.setState({ message: null });
  };

  setMessage(type: string, text: string) {
    this.setState({ message: { type: type, text: text } });
  }

  refreshTable = () => {
    this.setState({ dataLoaded: false, showConfirmDeleteHWModal: false, showAddHWModal: false }, () => {
      this.getNoSmartComponents();
    });
  };

  handleShowConfirmDeleteHWModal = (status: boolean, idCompoDelete?: RowHWHardwareTable) => {
    const idNoSmartToDelete = status && idCompoDelete && idCompoDelete.id ? idCompoDelete.id : null;
    this.setState({ showConfirmDeleteHWModal: status, idNoSmartToDelete });
  };

  render() {
    return (
      <>
        {this.state.message && (
          <Message type={this.state.message.type} content={this.state.message.text} onClose={this.handleMessageClose} />
        )}
        {this.state.dataLoaded ? (
          <HWHardwareView
            {...this.state}
            onSizePerPageChange={this.onSizePerPageChange}
            onPageChange={this.onPageChange}
            handleChangeFilter={this.handleChangeFilter}
            handleChangeSecondaryFilter={this.handleChangeSecondaryFilter}
            handleShowAddHWModal={this.handleShowAddHWModal}
            patchAvailableCheck={this.patchAvailableCheck}
            deleteHardware={this.deleteHardware}
            handleShowConfirmDeleteHWModal={this.handleShowConfirmDeleteHWModal}
            refreshAllNoSmartComponents={this.props.refreshAllNoSmartComponents}
            systemIds={this.props.systemIds}
            allSmartComponentTypes={this.props.allSmartComponentTypes}
          />
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

export default HWHardwareContainer;
