import { PureComponent } from 'react';
import AuthenticationContext from '../../../../../Authentication/types/AuthContextType';
import InstallationsGeneralView from '../view/InstallationsGeneralView';
import { InstallationsGeneralContainerProps, InstallationsGeneralContainerState } from '../types/InstallationsGeneralTypes';
import { Product } from '../../InstallationsLayout/types/DNDTypes';

class InstallationsGeneralContainer extends PureComponent<
  InstallationsGeneralContainerProps,
  InstallationsGeneralContainerState
> {
  static contextType = AuthenticationContext;

  constructor(props: InstallationsGeneralContainerProps) {
    super(props);
    this.state = {
      showLayout: true,
      showZoomModal: false,
      initialProducts: this.props.data.products,
      products: {
        active: [],
        inactive: [],
        coordsInPixel: false,
      },
      editMode: false,
      loading: true,
      loader: false,
      maxHeightContent: 0,
      maxWidthMap: 0,
      maxHeightMap: 0,
      floorPlan: this.props.data.floorPlanUri ? this.props.data.floorPlanUri : '',
    };

    this.toggleLayout = this.toggleLayout.bind(this);
    this.handleSetInitialProducts = this.handleSetInitialProducts.bind(this);
    this.handleLoader = this.handleLoader.bind(this);
    this.handleFloorPlan = this.handleFloorPlan.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem('installationGeneral-ShowLayout') == 'true') {
      this.setState({ showLayout: true });
    }
    // Add global mouseover listener
    document.addEventListener('mouseover', this.handleHover);
    document.addEventListener('mouseout', this.handleHoverOut);
  }

  componentWillUnmount() {
    // Remove global listener to avoid memory leaks
    document.removeEventListener('mouseover', this.handleHover);
    document.removeEventListener('mouseout', this.handleHoverOut);
  }

  // Reset initial positions of the products
  handleSetInitialProducts = (initialProducts: Product[]) => {
    this.setState({ initialProducts, editMode: false });
  };

  // --- highlight product on selected map with hover --- //
  handleHover = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const row = target.closest('.typeproducts.rs-table-row');
    if (row) {
      const eventSelector = row.querySelector('.eventSelector') as HTMLElement;
      if (eventSelector) {
        const puuid = eventSelector.getAttribute('puuid');
        if (puuid) {
          this.applyDimmedClass(puuid);
        }
      }
    }
  };
  handleHoverOut = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.closest('.typeproducts.rs-table-row')) {
      this.removeDimmedClasses();
    }
  };
  applyDimmedClass = (puuid: string) => {
    const mapProdElements = document.querySelectorAll('.mapProd');
    mapProdElements.forEach((element) => {
      const mapProd = element as HTMLElement;
      if (mapProd.getAttribute('x-puuid') !== puuid) {
        mapProd.classList.add('dimmed');
      } else {
        mapProd.classList.remove('dimmed');
      }
    });
  };
  removeDimmedClasses = () => {
    const mapProdElements = document.querySelectorAll('.mapProd');
    mapProdElements.forEach((element) => {
      (element as HTMLElement).classList.remove('dimmed');
    });
  };
  // --- highlight product on selected map with hover --- //

  toggleLayout() {
    this.setState({ showLayout: !this.state.showLayout });
  }

  handlerZoomShowModal = (status?: boolean) => {
    this.setState((prevState: any) => ({
      showZoomModal: status ? status : !prevState.showZoomModal,
    }));

    // closed modal --> recalculate product positions
    if (status === false) {
      this.calculateActiveProductsCoords('installationsGeneralView-content');
    }
  };

  calculateActiveProductsCoords = (parentClass: string) => {
    const floorPlanImg = document.querySelector(`.${parentClass} #floorPlanImg`);
    const DNDContent = document.querySelector(`.${parentClass} .DND-content`);
    if (floorPlanImg && DNDContent) {
      const { width: floorPlanWidth, height: floorPlanHeight } = floorPlanImg.getBoundingClientRect();
      const { height: maxHeightContent } = DNDContent.getBoundingClientRect();
      this.setActiveProductsCoordsToPixels(floorPlanWidth, floorPlanHeight, maxHeightContent);
    }
  };

  // Calculate active products coordinates in pixels
  setActiveProductsCoordsToPixels = (containerWidth: number, containerHeight: number, dndContentMaxHeight: number) => {
    const activeProducts =
      this.state.products.active
        ?.filter((product) => (product.floorPlanX && product.floorPlanY) || product.floorPlanPixel)
        .map((product) => ({
          ...product,
          floorPlanPixel: {
            x: (product.floorPlanX! / 100) * containerWidth, // % to px
            y: (product.floorPlanY! / 100) * containerHeight, // % to px
            maxHeightContent: dndContentMaxHeight,
            maxWidthMap: containerWidth,
            maxHeightMap: containerHeight,
          },
        })) || [];

    const updatedProducts = {
      ...this.state.products,
      active: activeProducts,
      coordsInPixel: true,
    };

    this.setState({
      products: updatedProducts,
      maxHeightContent: dndContentMaxHeight,
      maxWidthMap: containerWidth,
      maxHeightMap: containerHeight,
    });
  };

  handleUpdateProducts = (products) => {
    this.setState({ products });
  };

  handleManageProducts = () => {
    const products = this.state.initialProducts;
    const activeProducts = products?.filter((product) => product.floorPlanX && product.floorPlanY) || [];
    const inactiveProducts = products?.filter((product) => !product.floorPlanX && !product.floorPlanY) || [];
    this.setState({
      products: {
        active: activeProducts,
        inactive: inactiveProducts,
        coordsInPixel: false,
      },
      loading: false,
      editMode: false,
    });
  };

  handleChangeMode = (state: boolean) => {
    if (state) {
      this.setState({
        editMode: state,
      });
    } else {
      this.setState({ loading: true }, () => {
        this.handleManageProducts(); // resets the component to the initial coordinates
      });
    }
  };

  handleLoader = (state: boolean) => {
    this.setState({ loader: state ? state : !this.state.loader });
  };

  handleFloorPlan = (floorPlan: string) => {
    this.setState({ floorPlan });
  };

  render() {
    return (
      <>
        <InstallationsGeneralView
          showLayout={this.state.showLayout}
          notifications={this.props.notifications}
          data={this.props.data}
          initialProducts={this.state.initialProducts}
          showZoomModal={this.state.showZoomModal}
          products={this.state.products}
          editMode={this.state.editMode}
          loader={this.state.loader}
          loading={this.state.loading}
          maxHeightContent={this.state.maxHeightContent}
          maxWidthMap={this.state.maxWidthMap}
          maxHeightMap={this.state.maxHeightMap}
          floorPlan={this.state.floorPlan}
          toggleLayout={this.toggleLayout}
          handlerZoomShowModal={this.handlerZoomShowModal}
          handleUpdateProducts={this.handleUpdateProducts}
          handleManageProducts={this.handleManageProducts}
          handleChangeMode={this.handleChangeMode}
          calculateActiveProductsCoords={this.calculateActiveProductsCoords}
          handleSetInitialProducts={this.handleSetInitialProducts}
          handleLoader={this.handleLoader}
          handleFloorPlan={this.handleFloorPlan}
        ></InstallationsGeneralView>
      </>
    );
  }
}

export default InstallationsGeneralContainer;
