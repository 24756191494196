// TODO REFACTOR, separate in container/view + types

import React, { Component } from 'react';
import AuthenticationContext from '../../../../../Authentication/types/AuthContextType';
import { withTranslation } from 'react-i18next';
import ProductsHttpClient from '../../../../../HttpClient/ProductHttpClient';
import Error from '../../../../../components/Error';
import { manageInfoUser } from '../../../../Common/utils/helpers';

import SWPackageContainer from '../Software/container/SWPackageContainer';
import SWFirmwareContainer from '../Software/container/SWFirmwareContainer';

import HWHardwareContainer from '../Hardware/HardwareManagement/container/HWHardwareContainer';
import HWPackageContainer from '../Hardware/PackageManagement/container/HWPackageContainer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NoSmartComponentsHttpClient from '../../../../../HttpClient/NoSmartComponentsHttpClient';

class HWSWContainer extends Component {
  static contextType = AuthenticationContext;
  constructor(props) {
    super(props);
    this.state = {
      systemIds: null,
      dataLoaded: false,
      userMe: null,
      lastNoSmartComponents: false,
    };

    this.refreshDataTables.bind(this);
    this.refreshAllNoSmartComponents.bind(this);
  }

  async componentDidMount() {
    await this.getUserMe();
    await this.getSystemIds();
  }

  refreshDataTables = async () => {
    this.setState(
      {
        dataLoaded: false,
      },
      async () => {
        await this.getSystemIds();
      }
    );
  };

  refreshAllNoSmartComponents = async () => {
    this.setState(
      {
        lastNoSmartComponents: false,
      },
      async () => {
        this.http = new ProductsHttpClient(this.context, null);
        const httpNoSmartCompo = new NoSmartComponentsHttpClient(this.context);
        const allNoSmartComponents = await httpNoSmartCompo.GetNoSmartComponents();
        this.setState({ allNoSmartComponents: allNoSmartComponents?.content, lastNoSmartComponents: true });
      }
    );
  };

  async getSystemIds() {
    try {
      //Get all systems ID to add new Package
      this.http = new ProductsHttpClient(this.context, null);
      const httpNoSmartCompo = new NoSmartComponentsHttpClient(this.context);
      const allNoSmartComponents = await httpNoSmartCompo.GetNoSmartComponents();
      const allSystemIds = await this.http.ProductsGetSystems();
      const allSmartComponentTypes = await this.http.GetAllSmartComponentTypes();
      const allNoSmartComponentTypes = await httpNoSmartCompo.GetNoSmartComponentTypes();
      this.setState({
        systemIds: allSystemIds?.content,
        allSmartComponentTypes: allSmartComponentTypes?.content,
        allNoSmartComponents: allNoSmartComponents?.content,
        allNoSmartComponentTypes: allNoSmartComponentTypes?.content,
        dataLoaded: true,
        lastNoSmartComponents: true,
      });
    } catch (err) {
      console.error('Error loading Firware Packages');
      console.error(err);
    }
  }

  async getUserMe() {
    const userMe = await manageInfoUser(this.context, 'userBasicData');
    this.setState({ userMe });
  }

  checkRoles = () => {
    let rolesPermited = [100, 110];
    return this.state.userMe.userRoles.some((role) => rolesPermited.includes(role));
  };

  render() {
    const { t } = this.props;

    return this.state.dataLoaded ? (
      this.state.userMe != null && this.state.userMe.userRoles != null && this.checkRoles() ? (
        <div>
          <div className='mb-5' style={{ marginTop: '10px' }}>
            {this.state.dataLoaded ? (
              <div>
                <div>
                  {/* SOFTWARE */}
                  <Accordion defaultExpanded style={{ boxShadow: 'none' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h2 className='mt-5'>{t('firmwareManagementPage.software')}</h2>
                    </AccordionSummary>
                    <AccordionDetails className='container tableFirmware'>
                      <React.Fragment>
                        {/* PACKAGE MANAGEMENT */}
                        <SWPackageContainer
                          systemIds={this.state.systemIds}
                          allSmartComponentTypes={this.state.allSmartComponentTypes}
                          context={this.context}
                        />

                        {/* FIRMWARE MANAGEMENT */}
                        <SWFirmwareContainer
                          systemIds={this.state.systemIds}
                          allSmartComponentTypes={this.state.allSmartComponentTypes}
                          context={this.context}
                        />
                      </React.Fragment>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div style={{ marginTop: '10px' }}>
                  {/* HARDWARE */}
                  <Accordion defaultExpanded style={{ boxShadow: 'none' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h2 className='mt-5'>{t('firmwareManagementPage.hardware')}</h2>
                    </AccordionSummary>
                    <AccordionDetails className='container tableFirmware'>
                      <React.Fragment>
                        {/* PACKAGE MANAGEMENT */}

                          <HWPackageContainer
                            systemIds={this.state.systemIds}
                            allSmartComponentTypes={this.state.allSmartComponentTypes}
                            allNoSmartComponents={this.state.allNoSmartComponents}
                            allNoSmartComponentsTypes={this.state.allNoSmartComponentTypes}
                            refreshDataTables={this.refreshDataTables}
                            refreshAllNoSmartComponents={this.refreshAllNoSmartComponents}
                          />

                        {/* HARDWARE MANAGEMENT */}
                        {this.state.lastNoSmartComponents ? (
                          <HWHardwareContainer
                            systemIds={this.state.systemIds}
                            allSmartComponentTypes={this.state.allSmartComponentTypes}
                            allNoSmartComponents={this.state.allNoSmartComponents}
                            allNoSmartComponentsTypes={this.state.allNoSmartComponentTypes}
                            refreshDataTables={this.refreshDataTables}
                            refreshAllNoSmartComponents={this.refreshAllNoSmartComponents}
                          />
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            ) : (
              <div className='loadingParent'>
                <div className='loadingImg'></div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Error errors={{ status: 401 }} />
      )
    ) : (
      <div className='loadingParent'>
        <div className='loadingImg'></div>
      </div>
    );
  }
}

export default withTranslation()(HWSWContainer);
