import InstallationsGeneralMainChartsContainer from '../../General/InstallationsGeneralMainCharts/container/InstallationsGeneralMainChartsContainer';
import { InstallationsGeneralViewProps } from '../types/InstallationsGeneralTypes';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { t } from 'i18next';
import InstallationsTree from '../../InstallationsTree';
import DNDContainer from '../../InstallationsLayout/container/DNDContainer';
import SearchIcon from '@mui/icons-material/Search';
import Style from '../styles/InstallationsGeneralView.module.scss';
import ModalZoomMapView from '../../InstallationsLayout/view/ModalZoomMapView';

const InstallationsGeneralView = (props: InstallationsGeneralViewProps) => {
  return (
    <div className={Style.InstallationsGeneralView}>
      <div className='installationsGeneralView-content'>
        <div className='row'>
          <div
            className={
              ' col-12 col-xl-6 pr-xl-0 pl-xl-3 mb-5 '
            }
            style={{ height: 'fit-content' }}
          >
            <div
              className={'prodChildNoZoom ' + (props.showLayout ? '' : 'collapsed')}
              id='InstallationLayoutParent'
              style={{ margin: 0, padding: '20px 20px 20px', paddingTop: 0 }}
            >
              <div style={{ overflowX: 'auto' }}>
                <div
                  className={`row installationDropdownMap ${props.showLayout ? '' : 'collapsed2'}`}
                  style={{ margin: '10px 20px', marginTop: '2px', marginRight: 0 }}
                >
                  <h3 className='px-0 col'>{t('installationspages.layout')}</h3>
                  <div className='mapZoomIcon' onClick={() => props.handlerZoomShowModal()}>
                    <SearchIcon />
                  </div>
                  <IconButton
                    className='toggleLayoutBtn px-0'
                    onClick={(e) => props.toggleLayout()}
                    aria-label='close'
                    size='large'
                  >
                    {props.showLayout ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </IconButton>
                </div>
                {props.showLayout ? (
                  <>
                    <DNDContainer
                      data={props.data}
                      parentClass={'installationsGeneralView-content'}
                      handleUpdateProducts={props.handleUpdateProducts}
                      handleManageProducts={props.handleManageProducts}
                      handleChangeMode={props.handleChangeMode}   
                      calculateActiveProductsCoords={props.calculateActiveProductsCoords}
                      handleSetInitialProducts={props.handleSetInitialProducts}
                      handleLoader={props.handleLoader}
                      handleFloorPlan={props.handleFloorPlan}
                      products={props.products}
                      editMode={props.editMode}
                      loader={props.loader}
                      loading={props.loading}
                      maxHeightContent={props.maxHeightContent}
                      maxWidthMap={props.maxWidthMap}
                      maxHeightMap={props.maxHeightMap}
                      showZoomModal={props.showZoomModal}
                      floorPlan={props.floorPlan}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className='col order-xl-first' style={{ overflowX: 'auto', overflowY: 'hidden' }}>
            <InstallationsTree
              selectedKey={[]}
              notifications={props.notifications}
              // products={props.data.products}
              products={props.initialProducts}
              userPage={undefined}
            ></InstallationsTree>
          </div>

          <div style={{ margin: '20px 0px' }}>
            <InstallationsGeneralMainChartsContainer
              product={props.data}
            />
          </div>
        </div>
      </div>

      {props.showZoomModal ? (
        <ModalZoomMapView show={props.showZoomModal} handlerZoomShowModal={props.handlerZoomShowModal}>
          <DNDContainer
            data={props.data}
            parentClass={'zoomContainer'}
            handleUpdateProducts={props.handleUpdateProducts}
            handleManageProducts={props.handleManageProducts}
            handleChangeMode={props.handleChangeMode}   
            calculateActiveProductsCoords={props.calculateActiveProductsCoords}
            handleSetInitialProducts={props.handleSetInitialProducts}
            handleLoader={props.handleLoader}
            handleFloorPlan={props.handleFloorPlan}
            products={props.products}
            editMode={props.editMode}
            loader={props.loader}
            loading={props.loading}
            maxHeightContent={props.maxHeightContent}
            maxWidthMap={props.maxWidthMap}
            maxHeightMap={props.maxHeightMap}
            floorPlan={props.floorPlan}
          />
        </ModalZoomMapView>
      ) : (
        ''
      )}
    </div>
  );
};

export default InstallationsGeneralView;
