import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthenticationContext from '../../Authentication/types/AuthContextType';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Error from '../Error';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import ParameterToolTipV1 from './ParameterToolTipV1';

class CustomNumberSlider extends Component {
  static contextType = AuthenticationContext;

  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,

      min: this.props.paramObj.minValue ? this.props.paramObj.minValue : 0,
      max: this.props.paramObj.maxValue ? this.props.paramObj.maxValue : 0,
      value: this.props.parameterValue ? this.props.parameterValue : 0,
      needToConvert: this.calcNeedToConvert()
    };
  }

  calcNeedToConvert = () => {
    return (  
      this.props.paramObj.deviceUnits === 'ml/sec' &&
      this.props.paramObj.visibleUnits === 'l/min' &&
      this.props.paramObj.factorConversion &&
      ((this.props.paramObj.smartComponentTypeId === 20) || // SmartComponentType of LOFT
      (this.props.paramObj.smartComponentTypeId === 23)) // SmartComponentType of L-20
    );
  }

  checkIfValueNeedToConvert() {
    const {name, id} = this.props.paramObj
    const uniqueId = `${name}_${id}`;    
    return this.props.checkIfValueNeedToConvert(uniqueId);
  }

  componentDidMount() {
    const { factorConversion, minValue, maxValue } = this.props.paramObj;
    if (this.state.needToConvert) {
      const min = Number(minValue) * factorConversion;
      const max = Number(maxValue) * factorConversion;
      let value = this.state.value;
      
      if (this.checkIfValueNeedToConvert()) {
        const calculatedValue = this.props.parameterValue * factorConversion;
        value = Math.min(Math.max(Math.floor(calculatedValue * 100) / 100, min), max);
      }
      this.setState({
        min: String(min),
        max: String(max),
        value,
        dataLoaded: true
      });
    }
    else{
        if(this.props.paramObj !== null){
            this.setState({ dataLoaded: true });
        }
    }
  }

  onMouseOverItem = (e) => {
    let menuPosition = document.getElementsByClassName('MuiAppBar-root')[0].getBoundingClientRect().top;
    let sliderPosition = document.getElementById(e.currentTarget.id).getBoundingClientRect().top;

    let offset = sliderPosition - menuPosition;

    let element = document.getElementById('tooltip-' + e.currentTarget.id.replace('customNumberSlider-', ''));

    if (offset < 325) {
      if (element != undefined) {
        element.classList.remove('box');
        element.classList.add('BottomTooltip');
      }
    } else {
      if (element != undefined) {
        element.classList.remove('BottomTooltip');
        element.classList.add('box');
      }
    }
  };

  render() {

    const handleChange = (evnt, newValue) => {
      this.setState({ value: newValue });
    };

    const handleChangeCommited = (evnt, newValue) => {
      this.setState({ value: newValue });
      const needToConvert = this.calcNeedToConvert();
      this.props.toSend(this.props.paramObj, newValue, needToConvert);
      if (typeof this.props.onChange === 'function') {
        this.props.onChange('slider');
      }
    };

    const handleDisabledClick = (disabled) => {
      if (this.props.handleDisabledMessage && disabled) {
        this.props.handleDisabledMessage(disabled);
      }
    };

    if (this.state.dataLoaded) {
      let disabled = this.props.paramObj.permissionType == 'R' || !this.props.paramObj.online;
      const { t } = this.props;
      try {
        let style = {};
        if (this.props.propStyle) style = this.props.propStyle;

        let { min, max } = this.state;
        let minLabel = min;
        let maxLabel = max;
        if (this.props.paramObj.visibleUnits != undefined) {
          minLabel = min + ' ' + t('parametersPage:visibleUnits.' + this.props.paramObj.visibleUnits);
          maxLabel = max + ' ' + t('parametersPage:visibleUnits.' + this.props.paramObj.visibleUnits);
        } else if (this.props.paramObj.deviceUnits != undefined) {
          minLabel = min + ' ' + t('parametersPage:deviceUnits.' + this.props.paramObj.deviceUnits);
          maxLabel = max + ' ' + t('parametersPage:deviceUnits.' + this.props.paramObj.deviceUnits);
        }

        let name = t(
          this.props.paramObj.table +
            ':' +
            this.props.paramObj.smartComponentTypeId +
            '.' +
            this.props.paramObj.businessLabel +
            '.name'
        );
        let description = t(
          this.props.paramObj.table +
            ':' +
            this.props.paramObj.smartComponentTypeId +
            '.' +
            this.props.paramObj.businessLabel +
            '.description'
        );

        return (
          <FormControl
            id={'customNumberSlider-' + this.props.paramObj.businessLabel}
            className='customSlider'
            variant='standard'
            onMouseOver={this.onMouseOverItem}
          >
            <p>
              <ParameterToolTipV1 values={this.props.telemetryObject} description={description}>
                <Typography
                  className='t2'
                  id={'discrete-slider-restrict-' + this.props.paramObj.businessLabel}
                  gutterBottom
                  style={{ marginBottom: '2em', fontWeight: 'bold !important' }}
                >
                  {name}
                </Typography>
              </ParameterToolTipV1>
              <FormLabel className='custom-slider-label t1 d-none' id={'label-' + this.props.paramObj.businessLabel}>
                {description}
              </FormLabel>
            </p>
            {this.props.paramObj.infoText ? (
              <div className='info-tooltip-parameter'>
                <Tooltip title={this.props.paramObj.infoText}>
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              ''
            )}
            <div
              onClick={() =>
                handleDisabledClick({
                  deviceSetting: this.props.paramObj.isDeviceSettingsMode,
                  readOnly: this.props.paramObj.permissionType == 'R',
                  online: this.props.paramObj.online,
                })
              }
            >
              {/* TODO Hardcoded steps should come from the backend (we need to create a new column in the database). */}
              <Slider
                id={'s' + this.props.paramObj.businessLabel}
                name={'s' + this.props.paramObj.businessLabel}
                min={parseFloat(min)}
                max={parseFloat(max)}
                value={this.state.value === null ? min : Math.round(this.state.value)}
                aria-labelledby='discrete-slider-restrict'
                onChange={handleChange}
                onChangeCommitted={handleChangeCommited}
                step={this.props.paramObj.businessLabel === 'FlushDeactivationDelay' ? 0.5 : 1}
                disabled={disabled}
                valueLabelDisplay='on'
              />
            </div>
            <div className='custom-slider-label-group mb-4' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className={'label-min t3'}>{minLabel}</span>
              <span className={'label-max t3'}>{maxLabel}</span>
            </div>
          </FormControl>
        );
      } catch (e) {
        return <Error errors={e} />;
      }
    } else {
      return (
        <div className='row loadingParent'>
          <div className='loadingImg'></div>
        </div>
      );
    }
  }
}
export default withTranslation()(CustomNumberSlider);
