import { PureComponent } from 'react';
import DNDView from '../view/DNDView';
import Loader from '../../../../Common/Loader';
import { DNDContainerProps, DNDContainerState } from '../types/DNDTypes';
import { t } from 'i18next';
import InstallationsHttpClient from '../../../../../HttpClient/InstallationsHttpClient';
import AuthenticationContext from '../../../../../Authentication/types/AuthContextType';
import ProductsHttpClient from '../../../../../HttpClient/ProductHttpClient';
import '../../../Installations.css';

class DNDContainer extends PureComponent<DNDContainerProps, DNDContainerState> {
  static contextType = AuthenticationContext;
  constructor(props: DNDContainerProps) {
    super(props);
    this.state = {
      fileTypesAllowed: ['jpg', 'jpeg', 'png', 'pdf'],
      fileSizeLimitMB: 2,
      message: null,
      showConfirmDeleteModal: false,
    };
  }

  componentDidMount() {
    // If the products do not have the position set in pixels, it is calculated from the %
    {
      !this.props.products.coordsInPixel && this.props.handleManageProducts();
    }
  }

  getPercentualPosition = (translatedPixelX: number, translatedPixelY: number) => {
    const floorPlanImg = document.querySelector(`.${this.props.parentClass} #floorPlanImg`);
    if (floorPlanImg) {
      const { width, height } = floorPlanImg.getBoundingClientRect();
      return {
        translatedPercentualX: (translatedPixelX / width) * 100, // px to %
        translatedPercentualY: (translatedPixelY / height) * 100, // px to %
      };
    }
    return false;
  };

  newProductPosition = (event) => {
    const { active, over } = event;
    const fromDroppableId = active.data.current.fromDroppableId;
    const fromStatus = active.data.current.fromStatus;

    if (over && over.id !== fromDroppableId) {
      // been moved from container
      if (fromStatus === 'active' && over.id === 'unusued-droppable') {
        // active --> inactive
        this.moveProductToInactiveDroppable(event.active.id);
      } else if (fromStatus === 'inactive' && over.id === 'floorPlan-droppable') {
        // inactive --> active
        this.moveProductToActiveDroppable(over, active);
      }
    } else {
      // product moved inside the active container
      this.moveProductInsideActiveDroppable(event.delta.y, event.delta.x, event.active.id);
      //** */
    }
  };

  moveProductToActiveDroppable = (over, active) => {
    const droppableRect = over.rect; 
    const draggableRect = active.rect;
    const elementWidth = active.rect.current.initial.width;
    const elementHeight = active.rect.current.initial.height;

    // Calculate the position relative to the target droppable, adding the size of the element / 2 to center it
    const relativeX = draggableRect.current.translated.left - droppableRect.left + elementWidth / 2;
    const relativeY = draggableRect.current.translated.top - droppableRect.top + elementHeight / 2;

    this.moveProductInsideActiveDroppable(relativeY, relativeX, active.id);
  };

  moveProductToInactiveDroppable = (productMovedId: string) => {
    const updatedProducts = {
      ...this.props.products,
      active: [...this.props.products.active],
      inactive: [...this.props.products.inactive],
    };

    // Find the index of the product in the active array
    const productIndex = updatedProducts.active.findIndex((product) => product.puuid === productMovedId);

    if (productIndex === -1) {
      return null; // Do nothing if the product is not found
    }

    // Extract the selected product
    const [productSelected] = updatedProducts.active.splice(productIndex, 1); // Removes and extracts the product
    delete productSelected.floorPlanPixel; // Remove floorPlanPixel
    updatedProducts.inactive.push(productSelected); // Add to inactive
    this.props.handleUpdateProducts(updatedProducts); // Update the state with the new products    
  };

  // Check if the product is outside the map limits
  checkLimits = (translatedPixelX: number, translatedPixelY: number) => {
    const { maxHeightContent, maxWidthMap } = this.props;
    return {
      x: Math.min(Math.max(translatedPixelX, 1), maxWidthMap),
      y: Math.min(Math.max(translatedPixelY, 1), maxHeightContent - 28),
    };
  };

  moveProductInsideActiveDroppable = (deltaTop: number, deltaLeft: number, productMovedId: string) => {
    const updatedProducts = { ...this.props.products };
    let productIndex = updatedProducts.active.findIndex((product) => product.puuid === productMovedId);

    // product that was just added from inactive --> active
    if (productIndex === -1) {
      const inactiveIndex = updatedProducts.inactive.findIndex((product) => product.puuid === productMovedId);
      if (inactiveIndex !== -1) {
        const productSelected = updatedProducts.inactive[inactiveIndex];
        updatedProducts.inactive.splice(inactiveIndex, 1);
        updatedProducts.active.push(productSelected);
        productIndex = updatedProducts.active.findIndex((product) => product.puuid === productMovedId);
      }
    }

    // calculation of coords to apply to the product
    if (productIndex !== -1) {
      const selectedProduct = updatedProducts.active[productIndex];
      const currentPixel = selectedProduct.floorPlanPixel ? selectedProduct.floorPlanPixel : { x: 0, y: 0 };

      const translatedPixelX = currentPixel.x + deltaLeft;
      const translatedPixelY = currentPixel.y + deltaTop;

      // check if product is outside of Map (+25 for enable mid product outside)
      if (translatedPixelY > this.props.maxHeightMap) {
        this.moveProductToInactiveDroppable(productMovedId);
      }

      const translatedPixels = this.checkLimits(translatedPixelX, translatedPixelY);
      const percentualPosition = this.getPercentualPosition(translatedPixels.x, translatedPixels.y);

      updatedProducts.active[productIndex] = {
        ...updatedProducts.active[productIndex],
        floorPlanX: percentualPosition ? percentualPosition.translatedPercentualX : selectedProduct.floorPlanX,
        floorPlanY: percentualPosition ? percentualPosition.translatedPercentualY : selectedProduct.floorPlanY,
        floorPlanPixel: {
          x: translatedPixels.x,
          y: translatedPixels.y,
          maxHeightContent: this.props.maxHeightContent,
          maxWidthMap: this.props.maxWidthMap,
          maxHeightMap: this.props.maxHeightMap,
        },
      };
    }
    this.props.handleUpdateProducts(updatedProducts);
  };

  renderMessage = (type: string, content?: string) => {
    if (type === 'close' && !content) {
      this.setState({
        message: null,
      });
    } else {
      this.setState({
        message: {
          type: type,
          content: content!,
        },
      });
    }
  };

  handleUploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      let fileSizeInMB = file.size / (1024 * 1024); // Size in mb
      const extension = file.name.split('.').pop()?.toLowerCase();
      if (!extension || !this.state.fileTypesAllowed.includes(extension)) {
        this.renderMessage('error', 'Extension ' + extension + ' not accepted.');
      } else if (fileSizeInMB > this.state.fileSizeLimitMB) {
        this.renderMessage('error', String(t('messages.errorSizeFile', { size: `${this.state.fileSizeLimitMB}mb` })));
      } else {
        try {
          //upload file
          const id = parseInt(window.location.pathname.split('/')[2]);
          const httpClient = new InstallationsHttpClient(this.context, id);
          const form = new FormData();
          form.append('file', file);
          const response = await httpClient.InstallationUploadFile(form, id);
          if (response) {
            this.props.handleFloorPlan(response.content);
            // this.renderMessage('success', String(t('messages.successUploadImg')));
          } else {
            this.renderMessage('error', String(t('messages.errorUploadImg')));
          }
        } catch (err) {
          this.renderMessage('error', String(t('messages.errorUploadImg')));
        }
      }
    } else {
      this.renderMessage('error', String(t('messages.errorUploadImg')));
    }
  };

  handleDeleteImage = async () => {
    try {
      const id = parseInt(window.location.pathname.split('/')[2]);
      const httpClient = new InstallationsHttpClient(this.context, id);
      const response = await httpClient.deleteFloorplan(id);
      if (response?.content) {
        this.props.handleFloorPlan(response.content);
        this.setState({ showConfirmDeleteModal: false }, () => {
          // this.renderMessage('success', String(t('messages.successDeleteImage')));
        });
      } else {
        this.renderMessage('error', String(t('messages.ErrorDeleteImage')));
      }
    } catch (err) {
      console.log(err);
      this.renderMessage('error', String(t('messages.ErrorDeleteImage')));
    }
  };

  handleRotateImage = async (degrees: number) => {
    try {
      this.props.handleLoader(true);
      const id = parseInt(window.location.pathname.split('/')[2]);
      const httpClient = new InstallationsHttpClient(this.context, id);
      const response = await httpClient.rotateInstallationPlan(degrees);
      if (!response.errors && response.content) {
        this.props.handleFloorPlan(response.content);
        // this.renderMessage('success', String(t('messages.successRotateImage')));
      } else {
        this.props.handleLoader(true);
        this.renderMessage('error', String(t('messages.ErrorRotateImage')));
      }
      this.props.handleLoader(false);
    } catch (err) {
      console.error(err);
      this.renderMessage('error', String(t('messages.ErrorRotateImage')));
      this.props.handleLoader(false);
    }
  };

  preparePatchOperation = () => {
    const { products } = this.props;
    const patchOperations: {
      id: string;
      position: { op: string; path: string; value: string | null }[];
    }[] = [];
  
    // Extract 'active' and 'inactive'
    const { active = [], inactive = [] } = products;
  
    // Process active items
    active.forEach((item) => {
      // Function to limit values within the range [min, max]
      const setLimit = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);  
      patchOperations.push({
        id: item.id!,
        position: [
          {
            op: 'replace',
            path: '/floorPlanY',
            value: setLimit(item.floorPlanY!, 0, 100).toString(),
          },
          {
            op: 'replace',
            path: '/floorPlanX',
            value: setLimit(item.floorPlanX!, 0, 100).toString(),
          },
        ],
      });
    });
  
    // Process inactive items
    inactive.forEach((item) => {
      patchOperations.push({
        id: item.id!,
        position: [
          {
            op: 'replace',
            path: '/floorPlanY',
            value: null,
          },
          {
            op: 'replace',
            path: '/floorPlanX',
            value: null,
          },
        ],
      });
    });
    return patchOperations;
  };
  

  handleSave = async () => {
    this.props.handleLoader(true);
    try {
      const preparedProducts = this.preparePatchOperation();
      const id = parseInt(window.location.pathname.split('/')[2]);
      const httpProduct = new ProductsHttpClient(this.context, id);
      const formulario = new FormData();
      formulario.append('patchoptions', JSON.stringify(preparedProducts));
      const response = await httpProduct.PatchInstallationsProductsLocation(formulario);
      if (response?.content) {
        await this.setNewInitialProducts();
      } else {
        this.renderMessage('error', String(t('messages.errorSaveCoords')));
      }
    } catch (err) {
      this.renderMessage('error', String(t('messages.errorSaveCoords')));
    }
  };

  setNewInitialProducts = async () => {
    try {
      const id = parseInt(window.location.pathname.split('/')[2]);
      const httpClient = new InstallationsHttpClient(this.context, id);
      const response = await httpClient.InstallationGetInstallationsById();
      if (response && response.status == 200) {
        this.props.handleSetInitialProducts(response.data.content.products);
        this.props.handleLoader(false);
        // this.renderMessage('success', String(t('messages.successSaveCoords')));
      }
    } catch (err) {
      this.renderMessage('error', String(t('messages.errorSaveCoords')));
    }
  };

  handleConfirmDeleteModal = () => {
    this.setState({ showConfirmDeleteModal: !this.state.showConfirmDeleteModal });
  };

  render() {
    return (
      <>
        {this.props.loader && <Loader />}
        {!this.props.loading ? (
          <>
            <DNDView
              message={this.state.message}
              parentClass={this.props.parentClass}
              products={this.props.products}
              newProductPosition={this.newProductPosition.bind(this)}
              changeMode={this.props.handleChangeMode}
              calculateActiveProductsCoords={this.props.calculateActiveProductsCoords}
              editMode={this.props.editMode}
              maxHeightContent={this.props.maxHeightContent}
              maxWidthMap={this.props.maxWidthMap}
              maxHeightMap={this.props.maxHeightMap}
              showZoomModal={this.props.showZoomModal}
              handleUploadImage={this.handleUploadImage.bind(this)}
              handleDeleteImage={this.handleDeleteImage.bind(this)}
              handleRotateImage={this.handleRotateImage.bind(this)}
              handleSave={this.handleSave.bind(this)}
              fileTypesAllowed={this.state.fileTypesAllowed}
              renderMessage={this.renderMessage.bind(this)}
              floorPlan={this.props.floorPlan}
              showConfirmDeleteModal={this.state.showConfirmDeleteModal}
              handleConfirmDeleteModal={this.handleConfirmDeleteModal.bind(this)}
              loader={this.props.loader}
            />
          </>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default DNDContainer;
